export function getLabel (isDisabled: boolean, isPastDue: boolean): string | undefined {
    if (isDisabled) return "label.disabled";
    if (isPastDue) return "label.past_due";
    return undefined;
}

export function getType (isDisabled: boolean, isPastDue: boolean): string | undefined {
    if (isDisabled) return "danger";
    if (isPastDue) return "warning";
    return undefined;
}
